import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import Seo from "../components/seo/seo"
import { useTranslation } from "react-i18next"
import Img from 'gatsby-image'
import SvgCreateAccount from "../icons/CreateAccount"
import SvgSelectPlan from "../icons/SelectPlan"
import SvgBusinessResults from "../icons/BusinessResults"

const HeraWashConfigurator = ({ data }) => {

  const { t } = useTranslation();

  return (

  <Layout>
    <Seo
      title={t("mobileapp.seoTitle")}
      description={t("mobileapp.seoDescription")}
      seoImage={data.configurator.childImageSharp.resize.src}
    />
    {/* Sezione Header */}
    <div className="section is-medium has-light-background is-relative" style={{display: 'inline-block', width: '100%', paddingBottom: '100px',overflow: 'hidden', verticalAlign: 'middle'}}>
      <svg width="100vw" height="140vh" viewBox="0 0 100vw 140vh" style={{position: "absolute", display: 'inline-block', top: "0", left: 0, fillRule: "evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round"}} xmlns="http://www.w3.org/2000/svg" space="preserve" >
        <defs>
        <linearGradient y1="364.683" id="LinearGradient" x1="-2.95224" y2="364.683" x2="2005.71" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 0)">
        <stop stop-color="#7dd1a7" offset="0"/>
        <stop stop-color="#71bdf4" offset="1"/>
        </linearGradient>
        </defs>
        <g id="Livello 1" layerName="Livello 1">
        <path d="M-1.43513+0.575764L2002.67+0.575764L2000+381.632C2000+381.632+1827.32+319.668+1447.66+379.973C1068.01+440.279+830.684+620.543+474.162+617.13C109.78+613.64-2.95224+456.261-2.95224+456.261L-1.43513+0.575764Z" opacity="1" fill="url(#LinearGradient)"/>
        </g>
      </svg>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <div className="content is-large">
              <h1 className="title is-white is-1 is-bottom-bordered-white">{t('mobileapp.pageTitle')}</h1>
              <h2 className="title is-4" style={{marginTop: 0}}>{t('mobileapp.pageSubtitle')}</h2>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <Img fluid={data.configurator.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
    
     {/* Sezione Step per iniziare */}
     <div className="section is-medium has-light-background">
      <div className="container">
        <div className="content has-text-centered"><h2 className="title is-1">{t('mobileapp.ctaTitle')}</h2></div>
        <div className="columns" style={{paddingTop: '75px'}}>
          <div className="column is-4 has-text-centered" style={{padding: '0 50px 0 50px'}}>
          <SvgSelectPlan />
            <div className="content">
              <h4 className="title is-3">{t('mobileapp.cta1')}</h4>
            </div>
            {/* <div className="buttons" style={{display: 'block'}}>
                <div className="button is-outlined is-primary"><strong>iOS</strong></div>
                <div className="button is-outlined is-info"><strong>Android</strong></div>
            </div> */}
          </div>

          <div className="column is-4 has-text-centered" style={{padding: '0 50px 0 50px'}}>
          <SvgCreateAccount />
            <div className="content">
              <h4 className="title is-3">{t('mobileapp.cta2')}</h4>
            </div>
          </div>
          <div className="column is-4 has-text-centered" style={{padding: '0 50px 0 50px'}}>
            <SvgBusinessResults />
            <div className="content">
              <h4 className="title is-3">{t('mobileapp.cta3')}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Sezione descrittiva */}
    <div className="section is-medium">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <div className="content is-large">
              <h2 className="title is-3" style={{paddingBottom: '25px', borderBottom: '3px solid #7dd1a7'}}>{t('mobileapp.paragraphTitle')}</h2>
            </div>
            <div className="content">            
              <p>{t('mobileapp.paragraphText')}</p>
            </div>
          </div>
          <div className="column is-6" style={{height: '400px'}}>
            <Img fluid={data.app.childImageSharp.fluid}/>
          </div>
        </div>
      </div>
    </div>s    
    
  </Layout>
  )
}

export default HeraWashConfigurator

export const Home = graphql`
  query {
    app: file(
      relativePath: { eq: "Hera_laundry_mobile_app.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    configurator: file(
      relativePath: { eq: "Hera_Mobile_Wash_Cycle_Configurator.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
